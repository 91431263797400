import type { IPromocodeDetails } from '~/types/common';

export const getPriceLabel = (price: number, currencySign: string, priceNomination = 100) =>
  price / priceNomination + currencySign;

export const getDividedPriceLabel = (price: number, currencySign: string, priceNomination = 100) => (
  getPriceLabel(price, ' ' + currencySign, priceNomination)
);

export const getPromoPrice = (tariffPrice: number, promocodeData?: IPromocodeDetails) => {
  if (!promocodeData) {
    return tariffPrice;
  }

  const removingPrice = promocodeData.discount_money
    || (tariffPrice / 100 * promocodeData.discount_procent)
    || 0;

  return tariffPrice - Math.ceil(removingPrice / 100) * 100;
};